import React from 'react';
import PropTypes from 'prop-types';
import Helpers from '../Helpers';

import CountUp from 'react-countup';

import '../styles/ElectionBar.scss';

const ElectionBar = ({splits, leader1, leader2, year, numToWin}) => {

if(!splits || !leader1 || !leader2 || !year){
  return null;
}

return (
  <div className="electionBarContainer">
    <div className="leader1 leader">
      <img src={Helpers.getCandidateImg(leader1, year)} alt="Candidate" onError={(e) => { e.target.onerror = null; e.target.src = '/img/candidates/default.jpg'; }} />
      <div>
        {leader1.nameOverride && <span className="leaderName">{leader1.nameOverride}</span>}
        {!leader1.nameOverride && <span className="leaderName">{leader1.name}</span>}
        <div className="checkAndCount">
          <span className="barCount">
            {Math.round(splits[leader1.party])}
          </span>
          {splits[leader1.party] > numToWin && <svg class="checkmark democratic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle democratic" cx="26" cy="26" r="25" fill="none"/>
            <path class="checkmark__check democratic" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
          </svg>}
        </div>
      </div>
    </div>
    <div className="leader2 leader">
      <div>
        {leader2.nameOverride && <span className="leaderName">{leader2.nameOverride}</span>}
        {!leader2.nameOverride && <span className="leaderName">{leader2.name}</span>}
        <div className="checkAndCount">
          <span className="barCount">
              {Math.round(splits[leader2.party])}
          </span>
          {splits[leader2.party] > numToWin && <svg class="checkmark republican" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle class="checkmark__circle republican" cx="26" cy="26" r="25" fill="none"/>
              <path class="checkmark__check republican" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
          </svg>}
        </div>
      </div>
      <img src={Helpers.getCandidateImg(leader2, year)}  alt="Candidate" onError={(e) => { e.target.onerror = null; e.target.src = '/img/candidates/default.jpg'; }} />
    </div>
    <div className="electionBar">
      {
        Object.keys(splits).map((party, i) => (
          <div
            style={{ 
              background: Helpers.getPartyColor(party),
              flexGrow: `${splits[party]}`
            }}
            className={`electionBar-${party}`}
            key={party}
          ></div>
        ))
      }
    </div>
    <div>
      <div className="halfwayMark"></div>
      <span>{numToWin}</span>
    </div>
  </div>
)}
;

ElectionBar.propTypes = {
  splits: PropTypes.any.isRequired,
  total: PropTypes.any.isRequired,
};

export default ElectionBar;
