import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import * as predictionActions from '../actions/predictionActions';
import NavBar from './NavBar';
import ResultsTable from './ResultsTable';
import ResultsTableHouse from './ResultsTableHouse';
import Helpers from '../Helpers';
import * as constants from '../constants';

import '../styles/ResultsApp.css';

const client = axios.create({ //all axios can be used, shown in axios documentation
  baseURL: Helpers.getBaseURL()
});

class ResultsApp extends Component {
  constructor() {
    super();
    this.state = {
      predictions: [],
      showCloseRacesOnly: true,
      showDifferencesOnly: false,
      closeRaces: [],
      differences: [],
    };

    this.findDifferences = this.findDifferences.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
  }

  toggleFilter(filterState){
    switch(filterState){
      case 'showAll':
        this.setState({
          showCloseRacesOnly: false,
          showDifferencesOnly: false
        })
        return;
      case 'showCloseRacesOnly':
        this.setState({
          showCloseRacesOnly: true,
          showDifferencesOnly: false
        })
        return;
      case 'showDifferencesOnly':
        this.setState({
          showCloseRacesOnly: false,
          showDifferencesOnly: true
        })
        return;
      default:
    }
  }

  findDifferences(predictions){
    let differences = {};

    constants.ELECTION_TYPES.forEach(electionType => {
      let states = [];
      predictions.forEach(prediction => {
        states = [...new Set([...states, ...Object.keys(prediction[electionType])])]
      });
      
      states = states.filter(state => {
        let candidate = null;
        let isDifference = false;

        predictions.forEach(prediction => {
          if(prediction.predictionId === constants.RESULT_PREDICTION_NAME){
            return;
          }

          if(candidate === null){
            candidate = prediction[electionType][state];
          }
          
          if(!_.isEqual(candidate, prediction[electionType][state])){
            isDifference = true;
          }
        });
        return isDifference;
      });

      differences[electionType] = states;
    });
    return differences;
  }

  componentDidMount() {
    const targetPredictions = [constants.RESULT_PREDICTION_NAME, 'Sam55', 'Ben', 'SpencerB'];
    const year = parseInt(this.props.match.params.year);

    const queryString = `string_identifiers=${targetPredictions.join('&string_identifiers=')}&year=${year}`;

    this.setState({
      closeRaces : {
        [constants.ELECTION_TYPE_GOVERNOR] : [
          'Montana',
          'North Carolina',
          'New Hampshire',
        ],
        [constants.ELECTION_TYPE_SENATE] : [
          'Georgia',
          'Georgia Special',
          'Arizona',
          'Iowa',
          'Maine',
          'North Carolina',
          'Montana',
          'South Carolina',
          'Kansas',
          'Michigan',
          'Texas',
          'Colorado',
          'Alabama',
          'Mississippi',
          'Alaska'
        ],
        [constants.ELECTION_TYPE_PRESIDENT] : [
          'Wisconsin',
          'Michigan',
          'Nevada',
          'Pennsylvania',
          'Nebraska 02',
          'Arizona',
          'Florida',
          'North Carolina',
          'Maine 02',
          'Georgia',
          'Ohio',
          'Iowa',
          'Texas',
          'Montana',
        ],
      }
    });

    client.get(`/predictions?${queryString}`)
      .then((response) => {
        this.setState({ 
          predictions: response.data.predictions,
          differences: this.findDifferences(response.data.predictions)
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { electionType, data } = this.props;
    const { predictions } = this.state;

    let filteredElections = {};

    const electionTypes = constants.ELECTION_TYPES;
    electionTypes.forEach(electionType => {
      filteredElections[electionType] = Object.keys(data[electionType]);

      // if primary then filter to only elections that are closed
      if(electionType === constants.ELECTION_TYPE_PRIMARY){
        let primaryElections = data[electionType];

        filteredElections[electionType] = Object.keys(primaryElections).filter((state) => {
          return Helpers.isElectionClosed(Helpers.getDateOffset(primaryElections[state].date));
        });
      }

      if(this.state.showCloseRacesOnly){
        filteredElections[electionType] = _.intersection(filteredElections[electionType], this.state.closeRaces[electionType]);
      }
      if(this.state.showDifferencesOnly){
        filteredElections[electionType] = _.intersection(filteredElections[electionType], this.state.differences[electionType]);
      }
    });

    return (
        <>
          <NavBar
              handlePredictionIdChange={this.handlePredictionIdChange}
              electionType={electionType}
            />
          <div className="App container">
            <div>
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label className={"btn btn-light" + (this.state.showCloseRacesOnly ? ' active' : '')} onClick={() => this.toggleFilter('showCloseRacesOnly')}>
                  <input type="radio" name="options" id="option2" autoComplete="off" defaultChecked ={this.state.showCloseRacesOnly} /> Close Races
                </label>
                <label className={"btn btn-light" + (this.state.showDifferencesOnly ? ' active' : '')} onClick={() => this.toggleFilter('showDifferencesOnly')}>
                  <input type="radio" name="options" id="option3" autoComplete="off" defaultChecked ={this.state.showDifferencesOnly} /> Differences
                </label>
                <label className={"btn btn-light" + (!this.state.showCloseRacesOnly && !this.state.showDifferencesOnly ? ' active' : '')} onClick={() => this.toggleFilter('showAll')}>
                  <input type="radio" name="options" id="option1" autoComplete="off" defaultChecked ={!this.state.showCloseRacesOnly && !this.state.showDifferencesOnly} /> All
                </label>
              </div>
            </div>
            <ResultsTable
            predictions={predictions}
            elections={filteredElections}
            electionType ={constants.ELECTION_TYPE_PRESIDENT}
            />
            <ResultsTable
            predictions={predictions}
            elections={filteredElections}
            electionType ={constants.ELECTION_TYPE_SENATE}
            />
            <ResultsTable
              predictions={predictions}
              elections={filteredElections}
              electionType ={constants.ELECTION_TYPE_GOVERNOR}
            />
            <ResultsTableHouse
              predictions={predictions}
              electionType ={constants.ELECTION_TYPE_HOUSE}
            />
          </div>
        </>
    );
  }
}

const mapStateToProps = state => ({
  data: state.data
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(predictionActions, dispatch),
});

ResultsApp.propTypes = {
  data: PropTypes.any.isRequired,
  electionType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsApp);
