import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select'
import Helpers from '../Helpers';
import Countdown from 'react-countdown';

import '../styles/OverallNominee.css';

const OverallNominee = ({ candidates, predictions, handleWinnerSelect }) => {
  const overallElectionName = "Overall";
  const overallElectionDate = "2020-07-13";

  const handleChange = (value, stateName) => {
    handleWinnerSelect(value.data, stateName);
  };

  const overallElection = {
    [overallElectionName]: {
      "candidates" : candidates
    } 
  }

  const CandidateTrump = {
    name: "Donald Trump",
    party: "Republican"
  }
  
  const options = Helpers.populateCandidateChoices(overallElection)[overallElectionName];

  if(!candidates){
    return null;
  }

  return (
    <div className="OverallContainer predictionContainer">
      <h5>Overall Nominee</h5>
      <div className="OverallCandidatesContainer">
        <div>
          <img src={Helpers.getCandidateImg(predictions[overallElectionName], 2020)} style={{ width: '100px', height: '125px' }} alt="Candidate" onError={(e) => { e.target.onerror = null; e.target.src = '/img/candidates/default.jpg'; }}/>
            <Select
              options={options}
              value={predictions[overallElectionName] != null ? {label: predictions[overallElectionName].name, value: predictions[overallElectionName].name} : null}
              onChange={(value) => handleChange(value, overallElectionName)}
              isDisabled={Helpers.isElectionClosed(overallElectionDate)}
            />
        </div>
        <div style={{paddingTop: "55px"}}>
          <h4>VS</h4>
        </div>
        <div>
          <img src={Helpers.getCandidateImg(CandidateTrump, 2020)} style={{ width: '100px', height: '125px' }} alt="Candidate" onError={(e) => { e.target.onerror = null; e.target.src = '/img/candidates/default.jpg'; }}/>
          <h4>Donald J. Trump</h4>
        </div>
      </div>
      {"Locks in: "}<Countdown date={Helpers.getDateOffset(new Date(overallElectionDate))} />
    </div>
)};

OverallNominee.propTypes = {
  candidates: PropTypes.any.isRequired,
  predictions: PropTypes.any.isRequired,
};

export default OverallNominee;
