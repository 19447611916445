import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Helpers from '../Helpers';
import Select from 'react-select'
import Countdown from 'react-countdown';

import '../styles/ElectionList.css';

class ElectionList extends Component {
  constructor(){
    super();
    this.candidateChoices = null;
    this.electionsByDate = null;
    this.customStyles = null;
  }

  componentDidMount() {
    let { elections } = this.props;

    this.candidateChoices = Helpers.populateCandidateChoices(elections);
    this.electionsByDate = Helpers.SortElectionsByDate(elections);

    this.dates = Object.keys(this.electionsByDate).sort((a, b) => {return new Date(a) - new Date(b)});

    this.customStyles = {
      dropdownIndicator: (provided, state) => {
        const padding = "0px";
        return {
          ...provided,
          padding
        }
      }
    }
  }

  handleChange = (value, stateName) => {
    this.props.handleWinnerSelect(value.data, stateName);
  };

  getLockText = (date) => {
    if(Helpers.isElectionClosed(Helpers.getDateOffset(new Date(date)))){
      return null
    };

    return <span>Locks in: <Countdown date={Helpers.getDateOffset(new Date(date))} /></span>;
  };


  render = () => {
    const { elections, predictions } = this.props;

    if(!elections || !this.candidateChoices || !this.electionsByDate){
      return null;
    }

    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: "UTC" };
    
    return (
      <div className="electionListContainer">
        {this.dates.map((date) => (
          <div key={date.toString()} className="predictionContainer">
            <h5>{(new Date(date)).toLocaleDateString("en-US", options)}</h5>
            <div className="electionListDateContainer">
              {Object.keys(this.electionsByDate[date]).sort().map(stateName => (
                <div key={stateName} className="electionChoiceBox">
                  <h6> {stateName} </h6>
                  <Select
                    options={this.candidateChoices[stateName]}
                    value={predictions[stateName] != null ? {label: predictions[stateName].name, value: predictions[stateName].name} : null}
                    onChange={(value) => this.handleChange(value, stateName)}
                    styles={this.customStyles}
                    isDisabled={Helpers.isElectionClosed(Helpers.getDateOffset(new Date(date)))}
                  />
                </div>
              ))
              }
            </div>
            {this.getLockText(date)}
          </div>
        ))}
      </div>
    );
  };
}

ElectionList.propTypes = {
  elections: PropTypes.any.isRequired,
  predictions: PropTypes.any,
  handleWinnerSelect: PropTypes.func.isRequired,
};

export default ElectionList;
