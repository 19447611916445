import * as constants from './constants';

class Helpers {
  getPartyColor = (partyName) => {
    let partyColor = '';

    switch (partyName) {
      case 'Democratic':
        partyColor = '#415695';
        break;
      case 'Republican':
        partyColor = '#bd3d3c';
        break;
      case 'Independent':
        partyColor = '#666666';
        break;
      case 'Libertarian':
        partyColor = '#C8CF00';
        break;
      case 'Green':
        partyColor = '#17C70E';
        break;
      case 'Unassigned':
        partyColor = '#CCC'
        break;
      default:
        partyColor = '#555';
    }

    return partyColor;
  }

 getBaseURL = () => {
    var environment = process.env.NODE_ENV || 'development';
    switch(environment){
        case 'development':
            return 'http://minecraft.buyansky.com:5000/api';
        case 'production':
            return 'http://minecraft.buyansky.com:5000/api'
        default:
            return {};
    }
  }

  getSeatDivision = (numDemSeats) => {
    const diff = (numDemSeats - 217.5) * 2;
  
    if (diff > 0) {
      return `D+${diff}`;
    }
    return `R+${Math.abs(diff)}`;
  };

  getFriendlyElectionName = (electionType) => {
    switch(electionType){
      case constants.ELECTION_TYPE_GOVERNOR:
        return 'Governor';
      case constants.ELECTION_TYPE_HOUSE:
        return 'House';
      case constants.ELECTION_TYPE_SENATE:
        return 'Senator';
      case constants.ELECTION_TYPE_PRESIDENT:
          return 'President';
      default:
        return '';
    }
  };

  getCandidateOffsets = (name) => {
    return `0 ${constants.PRIMARY_CANDIDATE_OFFSETS[name] * 100 / 46}%`;
  };

  getCandidateImg = (candidate, year) => {
    if(!candidate){
      return "/img/candidates/default.jpg";
    }

    return `/img/candidates/${year}/${candidate.name.replace(/\s+/g, '')}.jpg`
  };

  SortElectionsByDate = (elections) => {
    let states = Object.keys(elections);
    let electionsByDate = {};
  
    states.forEach(state => {
      let dateOfElection = elections[state]["date"];
  
      if(!electionsByDate[dateOfElection]){
        electionsByDate[dateOfElection] = {};
      }
  
      electionsByDate[dateOfElection][state] = elections[state];
    });
  
    return electionsByDate;
  };

  isElectionClosed = (electionDate) => {
    if(!electionDate){
      return false;
    }

    return (new Date(electionDate)) <= (new Date())
  }

  populateCandidateChoices = (elections) => {
    let choices = {};

    if(!elections){
      return null;
    }

    Object.keys(elections).forEach(stateName => {
      choices[stateName] = elections[stateName].candidates.map((candidate) => (
          {
            label: candidate.name,
            value: candidate.name,
            data: candidate,
          }
      ));
    });

    return choices;
  };

  getDateOffset(date){
    date = new Date(date);
    return date.setHours(date.getHours() + 6);
  }

  populateSplits(predictionsAll){
    constants.ELECTION_TYPES.forEach((electionType) => {
      var predictions = predictionsAll[electionType];

      predictions.splits = this.calculateSplit(predictions, electionType);
    });
  }

  calculateSplit(predictions, electionType){
    var splits = {};
    var unassigned;

    if(electionType  === constants.ELECTION_TYPE_PRESIDENT){
      unassigned = constants.NUM_EVS;
      splits["Republican"] = .00001;
      splits["Democratic"] = .00001;
    }
    else if (electionType  === constants.ELECTION_TYPE_SENATE){
      unassigned = constants.NUM_SENATORS - constants.NUM_DEM_SENATORS - constants.NUM_REP_SENATORS;
      splits["Republican"] = constants.NUM_REP_SENATORS;
      splits["Democratic"] = constants.NUM_DEM_SENATORS;
    }
    else if (electionType === constants.ELECTION_TYPE_GOVERNOR){
      unassigned = constants.NUM_GOVERNORS - constants.NUM_DEM_GOVERNORS - constants.NUM_REP_GOVERNORS;
      splits["Republican"] = constants.NUM_REP_GOVERNORS;
      splits["Democratic"] = constants.NUM_DEM_GOVERNORS;
    }
    else if (electionType === constants.ELECTION_TYPE_HOUSE){
      unassigned = 0;
      var numDemSeats = 218;

      if(predictions["numDemSeats"]){
        numDemSeats = predictions["numDemSeats"];
      }

      splits["Republican"] = constants.NUM_HOUSE_SEATS - numDemSeats;
      splits["Democratic"] = numDemSeats;
      return splits;
    }

    Object.keys(predictions).forEach((stateName) => {
      if(stateName === "splits"){
        return;
      }
      const party = predictions[stateName].party;

      var value = 1;

      if(electionType  === constants.ELECTION_TYPE_PRESIDENT){
        value = constants.ELECTORAL_VALUES[stateName];
      }

      if(!splits[party] || splits[party] < 1){
        splits[party] = value;
      }
      else{
        splits[party] += value;
      }

      unassigned -= value;
    });

    splits["Unassigned"] = unassigned;
    return splits;
  }
}

export default new Helpers();
