export const ELECTION_TYPE_PRESIDENT = 'electionsPresident';
export const ELECTION_TYPE_HOUSE = 'electionsHouse';
export const ELECTION_TYPE_SENATE = 'electionsSenate';
export const ELECTION_TYPE_GOVERNOR = 'electionsGovernor';
export const ELECTION_TYPE_PRIMARY = 'electionsPrimary';
export const ELECTION_TYPE_RESULTS = 'electionsResults';

export const ELECTION_TYPES = [ELECTION_TYPE_HOUSE, ELECTION_TYPE_GOVERNOR, ELECTION_TYPE_PRESIDENT, ELECTION_TYPE_SENATE];

export const RESULT_PREDICTION_NAME = 'Results';

export const PRIMARY_CANDIDATE_OFFSETS = {
    'Joe Biden' : 2,
    'Elizabeth Warren' : 43,
    'Bernie Sanders' : 35,
    'Pete Buttigieg' : 7,
    'Andrew Yang' : 46,
    'Mike Bloomberg' : 3,
    'Amy Klobuchar' : 23,
    'Cory Booker' : 4,
    'Julián Castro' : 9,
    'John Delaney' : 12,
    'Tulsi Gabbard' : 13,
    'Deval Patrick' : 33,
    'Tom Steyer' : 39,
    'Michael Bennet' : 1,
    'Marianne Williamson' : 45 
};

export const STATE_VIEWBOXES = {
    "Alabama": {
      "width": 53.26171875,
      "height": 86.9453125
    },
    "Alaska": {
      "width": 200.4453125,
      "height": 107.37890625
    },
    "Arizona": {
      "width": 94.08984375,
      "height": 109.96484375
    },
    "Colorado": {
      "width": 100.52734375,
      "height": 79.63671875
    },
    "Florida": {
      "width": 126.01171875,
      "height": 109.00390625
    },
    "Georgia": {
      "width": 75.2265625,
      "height": 78.74609375
    },
    "Indiana": {
      "width": 42.85546875,
      "height": 74.078125
    },
    "Kansas": {
      "width": 102.44140625,
      "height": 55.49609375
    },
    "Maine": {
      "width": 50.9140625,
      "height": 80.44921875
    },
    "Massachusetts": {
      "width": 48.05078125,
      "height": 26.51171875
    },
    "Minnesota": {
      "width": 90.96875,
      "height": 102.3984375
    },
    "New Jersey": {
      "width": 18.46484375,
      "height": 42.46484375
    },
    "North Carolina": {
      "width": 122.03125,
      "height": 54.01953125
    },
    "North Dakota": {
      "width": 90.921875,
      "height": 57.07421875
    },
    "Oklahoma": {
      "width": 119.34765625,
      "height": 62.95703125
    },
    "Pennsylvania": {
      "width": 80.390625,
      "height": 52.109375
    },
    "South Dakota": {
      "width": 96.54296875,
      "height": 64.78515625
    },
    "Texas": {
      "width": 193.703125,
      "height": 189.94140625
    },
    "Wyoming": {
      "width": 96.5546875,
      "height": 80.40234375
    },
    "Connecticut": {
      "width": 23.8984375,
      "height": 23.5625
    },
    "Missouri": {
      "width": 92.25,
      "height": 80.3046875
    },
    "West Virginia": {
      "width": 62.05859375,
      "height": 62.2578125
    },
    "Illinois": {
      "width": 55.20703125,
      "height": 98.3671875
    },
    "New Mexico": {
      "width": 96.640625,
      "height": 100.32421875
    },
    "Arkansas": {
      "width": 69.75390625,
      "height": 63.41796875
    },
    "California": {
      "width": 110.98828125,
      "height": 190.1953125
    },
    "Delaware": {
      "width": 14.50390625,
      "height": 24.203125
    },
    "District of Columbia": {
      "width": 2.921875,
      "height": 3.51953125
    },
    "Hawaii": {
      "width": 89.234375,
      "height": 57.63671875
    },
    "Iowa": {
      "width": 83.5625,
      "height": 54.93359375
    },
    "Kentucky": {
      "width": 102.38671875,
      "height": 52.546875
    },
    "Maryland": {
      "width": 62.70703125,
      "height": 31.45703125
    },
    "Michigan": {
      "width": 104.7890625,
      "height": 110.140625
    },
    "Mississippi": {
      "width": 49.671875,
      "height": 85.765625
    },
    "Montana": {
      "width": 141.22265625,
      "height": 89.75
    },
    "New Hampshire": {
      "width": 22.76171875,
      "height": 48.03125
    },
    "New York": {
      "width": 104.41015625,
      "height": 79.7578125
    },
    "Ohio": {
      "width": 57.9296875,
      "height": 66.26953125
    },
    "Oregon": {
      "width": 111.22265625,
      "height": 93.84375
    },
    "Tennessee": {
      "width": 117.82421875,
      "height": 41.0625
    },
    "Utah": {
      "width": 77.75390625,
      "height": 97.625
    },
    "Virginia": {
      "width": 109.7890625,
      "height": 61.23828125
    },
    "Washington": {
      "width": 92.75390625,
      "height": 68.75390625
    },
    "Wisconsin": {
      "width": 74.25,
      "height": 79.90625
    },
    "Nebraska": {
      "width": 114.2421875,
      "height": 57.0859375
    },
    "South Carolina": {
      "width": 70.40234375,
      "height": 53.7421875
    },
    "Idaho": {
      "width": 82.41796875,
      "height": 133.5859375
    },
    "Nevada": {
      "width": 88.046875,
      "height": 136.16796875
    },
    "Vermont": {
      "width": 23.33984375,
      "height": 43.97265625
    },
    "Louisiana": {
      "width": 80.03125,
      "height": 69.62109375
    },
    "Rhode Island": {
      "width": 10.76953125,
      "height": 15.54296875
    }
  };
  
export const ELECTORAL_VALUES = {
  "California" : 55,
  "Texas" : 38,
  "Florida" : 29,
  "New York" : 29,
  "Illinois" : 20,
  "Pennsylvania" : 20,
  "Ohio" : 18,
  "Georgia" : 16,
  "Michigan" : 16,
  "North Carolina" : 15,
  "New Jersey" : 14,
  "Virginia" : 13,
  "Washington" : 12,
  "Arizona" : 11,
  "Indiana" : 11,
  "Massachusetts" : 11,
  "Tennessee" : 11,
  "Maryland" : 10,
  "Minnesota" : 10,
  "Missouri" : 10,
  "Wisconsin" : 10,
  "Alabama" : 9,
  "Colorado" : 9,
  "South Carolina" : 9,
  "Kentucky" : 8,
  "Louisiana" : 8,
  "Connecticut" : 7,
  "Oklahoma" : 7,
  "Oregon" : 7,
  "Arkansas" : 6,
  "Iowa" : 6,
  "Kansas" : 6,
  "Mississippi" : 6,
  "Nevada" : 6,
  "Utah" : 6,
  "Nebraska" : 2,
  "Nebraska 01" : 1,
  "Nebraska 02" : 1,
  "Nebraska 03" : 1,
  "New Mexico" : 5,
  "West Virginia" : 5,
  "Hawaii" : 4,
  "Idaho" : 4,
  "Maine" : 2,
  "Maine 01" : 1,
  "Maine 02" : 1,
  "New Hampshire" : 4,
  "Rhode Island" : 4,
  "Alaska" : 3,
  "Delaware" : 3,
  "Montana" : 3,
  "North Dakota" : 3,
  "South Dakota" : 3,
  "Vermont" : 3,
  "Wyoming" : 3,
  "Washington DC" : 3
};

// President
export const NUM_EVS = 538;
export const NUM_EVS_TO_WIN = 269;

// Senate
export const NUM_SENATORS = 100;
export const NUM_SENATORS_TO_WIN = 50;

export const NUM_DEM_SENATORS = 35;
export const NUM_REP_SENATORS = 30;

// Governor
export const NUM_GOVERNORS = 50;
export const NUM_DEM_GOVERNORS = 20;
export const NUM_REP_GOVERNORS = 19;
export const NUM_GOVERNORS_TO_WIN = 25;

// House
export const NUM_HOUSE_SEATS = 435;
export const NUM_HOUSE_SEATS_TO_WIN = 217;