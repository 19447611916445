import React, { Component } from 'react';
import { feature } from 'topojson-client';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import HouseSlider from './HouseSlider';
import Map from './Map';
import NavBar from './NavBar';
import ElectionBar from './ElectionBar';
import geographyObject from '../data/map.json';
import * as predictionActions from '../actions/predictionActions';

import '../styles/App.css';
import * as constants from '../constants';

class HouseApp extends Component {
  constructor() {
    super();
    this.state = {
      geographyPaths: [],
    };

    this.handleNumSeatsChange = this.handleNumSeatsChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handlePredictionIdChange = this.handlePredictionIdChange.bind(this);
  }

  componentDidMount() {
    const geographyPaths = feature(
      geographyObject,
      geographyObject.objects[Object.keys(geographyObject.objects)[1]],
    ).features;
    this.setState({ geographyPaths });

    const leader1 = {
      name: 'Nancy Pelosi',
      party: 'Democratic',
      nameOverride: 'Democratic'
    };
    
    const leader2 = {
      name: 'Kevin McCarthy',
      party: 'Republican',
      nameOverride: 'Republican'
    };

    const numToWin = constants.NUM_HOUSE_SEATS_TO_WIN;

    this.setState({ leader1, leader2, numToWin });
  }

  handleNumSeatsChange(numDemSeats) {
    const { actions, electionType } = this.props;
    actions.predictHouse({ numDemSeats, electionType });
  }

  handleSave() {
    const { actions, predictionsAll } = this.props;
    const year = parseInt(this.props.match.params.year);

    actions.saveData(predictionsAll, year);
  }

  handleLoad() {
    const { actions, predictionsAll } = this.props;
    const year = parseInt(this.props.match.params.year);

    actions.loadData(predictionsAll.predictionId, year);
  }

  handlePredictionIdChange(event) {
    const { actions } = this.props;
    actions.updatePredictionId(event.target.value);
  }

  render() {
    const { geographyPaths, leader1, leader2, numToWin } = this.state;
    const { predictionsAll, electionType, data } = this.props;

    const predictions = predictionsAll[electionType];
    const splits = predictions.splits;
    const year = parseInt(this.props.match.params.year);
    const partisanIndex = data[electionType];
    const numDemSeats = predictionsAll[electionType].numDemSeats;

    return (
      <>
        <NavBar 
          handleSave={this.handleSave}
          handleLoad={this.handleLoad}
          handlePredictionIdChange={this.handlePredictionIdChange}
          predictionId={predictionsAll.predictionId}
          electionType={electionType}
        />
      <div className="App container">
        <h1 className="display-4 font-weight-normal col-12 text-center">
          2020 - House
        </h1>
        <div className="predictionContainer">
          <ElectionBar
            splits={splits}
            leader1={leader1}
            leader2={leader2}
            year={year}
            numToWin={numToWin}
          />
        <Map
          numDemSeats={numDemSeats}
          geography={geographyPaths}
          partisanIndex={partisanIndex}
        />
        </div>
        <HouseSlider
          numDemSeats={numDemSeats}
          handleNumSeatsChange={this.handleNumSeatsChange}
        />
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  predictionsAll: state.predictions,
  data: state.data,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(predictionActions, dispatch),
});

HouseApp.propTypes = {
  actions: PropTypes.any.isRequired,
  electionType: PropTypes.string.isRequired,
  predictions: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HouseApp);
