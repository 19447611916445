import React, { Component } from 'react';
import { feature } from 'topojson-client';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import geographyObject from '../data/map.json';
import Map from './Map';
import ElectionTable from './ElectionTable';
import ElectionList from './ElectionList';
import ElectionBar from './ElectionBar';
import OverallNominee from './OverallNominee';
import NavBar from './NavBar';
import Helpers from '../Helpers';
import * as predictionActions from '../actions/predictionActions';

import '../styles/App.css';
import * as constants from '../constants';

class App extends Component {
  constructor() {
    super();
    this.state = {
      geographyPaths: [],
      selectedStateName: 'Massachusetts',
    };

    this.handleStateSelect = this.handleStateSelect.bind(this);
    this.handleWinnerSelect = this.handleWinnerSelect.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handlePredictionIdChange = this.handlePredictionIdChange.bind(this);
  }

  componentDidMount() {
    const year = parseInt(this.props.match.params.year);

    const { electionType, data } = this.props;
    const elections = data[electionType];

    const geographyPaths = feature(
      geographyObject,
      geographyObject.objects[Object.keys(geographyObject.objects)[0]],
    ).features;

    this.setState({ geographyPaths });

    var leader1;
    var leader2;
    var numToWin;

    // Set bar people
    if(electionType === constants.ELECTION_TYPE_SENATE){
      leader1 = {
        name: 'Chuck Schumer',
        party: 'Democratic',
        nameOverride: 'Democratic'
      };
      
      leader2 = {
        name: 'Mitch McConnell',
        party: 'Republican',
        nameOverride: 'Republican'
      };

      numToWin = constants.NUM_SENATORS_TO_WIN;
    }
    else if(electionType === constants.ELECTION_TYPE_GOVERNOR){
      leader1 = {
        name: 'Phil Murphy',
        party: 'Democratic',
        nameOverride: 'Democratic'
      };
      
      leader2 = {
        name: 'Greg Abbott',
        party: 'Republican',
        nameOverride: 'Republican'
      };
      
      numToWin = constants.NUM_GOVERNORS_TO_WIN;
    }
    else if(electionType === constants.ELECTION_TYPE_PRESIDENT){
      leader1 = {
        name: 'Joe Biden',
        party: 'Democratic'
      };
      
      leader2 = {
        name: 'Donald J. Trump',
        party: 'Republican'
      };

      
      numToWin = constants.NUM_EVS_TO_WIN;
    }

    this.setState({ leader1, leader2, numToWin });

    // Preload images
    Object.keys(constants.PRIMARY_CANDIDATE_OFFSETS).forEach((candidate) => {
      const img = new Image();
      img.src = Helpers.getCandidateImg({name: candidate}, year);
    });
  
    this.overallCandidates = elections[Object.keys(elections)[0]].candidates;
  }

  handleStateSelect(stateName) {
    const { electionType } = this.props;
    let { selectedCandidate } = this.state;  

    if(electionType === constants.ELECTION_TYPE_PRIMARY && selectedCandidate){
      this.handleWinnerSelect(selectedCandidate, stateName);
    }
    else{
      //this.setState({ selectedStateName: stateName });
      const nextCandidate = this.getNextCandidate(stateName);
      this.handleWinnerSelect(nextCandidate, stateName);
    }
  }

  handleWinnerSelect(candidate, stateName) {
    const { actions, electionType, data } = this.props;
    const elections = data[electionType];

    if (!candidate || !candidate.name || !candidate.party) {
      return;
    }

    if(elections && elections[stateName]){
      if (Helpers.isElectionClosed(Helpers.getDateOffset(elections[stateName].date))){
        return;
      }
    }

    if(stateName){
      actions.predictElection({ candidate, stateName, electionType });
    }
    else{
      this.setState({ selectedCandidate: candidate });
    }
  }

  handleSave() {
    const { actions, predictionsAll } = this.props;
    const year = parseInt(this.props.match.params.year);

    actions.saveData(predictionsAll, year);
  }

  handleLoad() {
    const { actions, predictionsAll } = this.props;
    const year = parseInt(this.props.match.params.year);
    
    actions.loadData(predictionsAll.predictionId, year);
  }

  handlePredictionIdChange(event) {
    const { actions } = this.props;
    actions.updatePredictionId(event.target.value);
  }

  getElectionHeader(electionType, year) {
    let header = "";

    if(electionType === constants.ELECTION_TYPE_SENATE){
      header = `${year} Senate`;
    }
    else if(electionType === constants.ELECTION_TYPE_GOVERNOR){
      header = `${year} Governor`;
    }
    else if(electionType === constants.ELECTION_TYPE_PRIMARY){
      header = `${year} Democratic Primary`;
    }
    else if(electionType === constants.ELECTION_TYPE_PRESIDENT){
        header = `${year} President`;
    }

    return header;
  }

  getNextCandidate(stateName){
    const { electionType, data, predictionsAll } = this.props;

    const stateElection = data[electionType][stateName];
    const predictions = predictionsAll[electionType];
    const curCandidate = predictions[stateName];
    const candidates = stateElection.candidates.sort((a, b) => {
      return (this.getPartyValue(a.party) - this.getPartyValue(b.party));
    });
    var nextIndex = 0

    // if current prediction, go to the next candidate in array
    if(curCandidate){
      nextIndex = candidates.findIndex(e => JSON.stringify(e) === JSON.stringify(curCandidate)) + 1;
    }

    if(nextIndex === candidates.length){
      nextIndex = 0;
    }

    return candidates[nextIndex];
  }

  getPartyValue(party){
    if(party === "Democratic"){
      return 0;
    }
    else if(party === "Republican"){
      return 1;
    }
    
    return 2;
  }

  render() {
    const { data, predictionsAll, electionType } = this.props;
    const { selectedStateName, geographyPaths, leader1, leader2, numToWin } = this.state;

    const elections = data[electionType];
    const predictions = predictionsAll[electionType];
    const splits = predictions.splits;
    const year = parseInt(this.props.match.params.year);

    return (elections != null
      ? (
        <>
        <NavBar 
          handleSave={this.handleSave}
          handleLoad={this.handleLoad}
          handlePredictionIdChange={this.handlePredictionIdChange}
          predictionId={predictionsAll.predictionId}
          electionType={electionType}
        />
        <div className="App container">
          <h1 className="display-4 font-weight-normal col-12 text-center">
            {this.getElectionHeader(electionType, year)}
          </h1>
          {electionType === constants.ELECTION_TYPE_PRIMARY && this.overallCandidates &&
          <OverallNominee
            candidates={this.overallCandidates}
            predictions={predictions}
            handleWinnerSelect={this.handleWinnerSelect}
          />
          }
          <div className="predictionContainer">
            <ElectionBar
              splits={splits}
              leader1={leader1}
              leader2={leader2}
              year={year}
              numToWin={numToWin}
            />
            <Map
              geography={geographyPaths}
              elections={elections}
              predictions={predictions}
              handleStateSelect={this.handleStateSelect}
              electionType={electionType}
              year={year}
            />
          </div>
          {electionType !== constants.ELECTION_TYPE_PRIMARY && 
          <ElectionTable
            elections={elections}
            predictions={predictions}
            handleWinnerSelect={this.handleWinnerSelect}
            selectedStateName={selectedStateName}
            electionType={electionType}
          />
          }
          {electionType === constants.ELECTION_TYPE_PRIMARY && 
          <ElectionList
            elections={elections}
            predictions={predictions}
            handleWinnerSelect={this.handleWinnerSelect}
            selectedStateName={selectedStateName}
            electionType={electionType}
            year={year}
          />
          }
        </div>
          </>
      ) : null
    );
  }
}

const mapStateToProps = state => ({
  predictionsAll: state.predictions,
  data: state.data
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(predictionActions, dispatch),
});

App.propTypes = {
  predictionsAll: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  actions: PropTypes.any.isRequired,
  electionType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
